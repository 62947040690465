<template>
  <div id="feedbackBox">

  </div>
</template>

<script>
export default {
name: "feedback-box-component"
}
</script>

<style scoped lang="scss">
  #feedbackBox{
    display: flex;
    flex-direction: column;
    border: 1px dashed tomato;
    width: 100%;
    height: 10em;
  }
</style>
